import { IEarlyRepaymentItem, OrderTypeEnum, UnionOrderType } from '../../models'

export const earlyRepaymentToOrder = (value: IEarlyRepaymentItem): Partial<UnionOrderType> => {
    return {
        id: value.uuid,
        emitter_id: value.emitter_id,
        emitter_full_name: value.emitter_full_name,
        price_per_dfa: value.price_per_dfa,
        total_price: value.redeem_total_price,
        asset_id: +value.asset_id,
        asset_ticker_symbol: value.asset_ticker_symbol,
        amount_dfa: value.amount,
        order_type: OrderTypeEnum.early_repayment,
        status: value.status,
        created_at: value.created_at ? new Date(value.created_at).valueOf() / 1000 : undefined,
    }
}

export const earlyRepaymentsToOrders = (
    values?: IEarlyRepaymentItem[] | null,
): Partial<UnionOrderType>[] => {
    if (!values || !values?.length) {
        return []
    }

    return values.map(earlyRepaymentToOrder)
}
