import { UploadFile } from 'antd/lib/upload/interface'
import dayjs from 'dayjs'

import {
    DecisionMakingType,
    DfaStatusEnum,
    ExternalRateIndex,
    IDfaAvailable,
    IDfaBack,
    IDfaDecision,
    IDfaEmitterInfo,
    IDfaFront,
    IssueAvailableInvestorsType,
    IssueTypeDfa,
    PriceSource,
    RedemptionTypeDfa,
    SettlementsType,
} from '../../models'
import { SupplyInvestedType } from '../../models/dfa/countDfa'
import { currentUnix, getBasePercent, safeToUtc } from '../../utils'
import { getPennies, stringToNumber } from '../../utils/number'

import { getDocumentUid, getInitialFile } from '../common/files'

export const dfaFrontToBack = (dfaIn: IDfaFront): IDfaBack => {
    return {
        title: dfaIn.title,
        ticker_symbol: dfaIn.ticker_symbol,
        emitter_id: dfaIn.emitter_id,
        status: dfaIn.status,
        decision_making_type: dfaIn.decision_making_type,
        issue_settlements_type: dfaIn.issue_settlements_type,
        repayment_settlements_type: dfaIn.repayment_settlements_type,
        is_force_repayment: dfaIn.is_force_repayment,
        price_source_type: dfaIn.price_source_type,
        price_source: dfaIn.price_source,
        investors: dfaIn.is_limited_circle_investors ? dfaIn.investors : [],
        is_limited_circle_investors: dfaIn.is_limited_circle_investors,
        redemption_type: dfaIn.redemption_type,
        redemption_description: dfaIn.redemption_description,

        update_date: currentUnix(),
        payment_collect_type:
            dfaIn.issue_settlements_type === SettlementsType.platform
                ? dfaIn.payment_collect_type
                : undefined,
        repayment_collect_type:
            dfaIn.repayment_settlements_type === SettlementsType.platform
                ? dfaIn.repayment_collect_type
                : undefined,
        issue_type: dfaIn.issue_type
            ? dfaIn.issue_type
            : dfaIn.decision_making_type === DecisionMakingType.platform
            ? IssueTypeDfa.auto
            : IssueTypeDfa.manual,
        decision: {
            emitter_id: dfaIn.emitter_id,
            issue_restrictions: dfaIn.issue_restrictions,
            issue_start_date: dfaIn.issue_start_date
                ? (safeToUtc(dfaIn.issue_start_date, true) as number)
                : undefined,
            original_amount: dfaIn.original_amount ? Number(dfaIn.original_amount) : undefined,
            original_price:
                dfaIn.price_per_dfa && dfaIn.original_amount
                    ? getPennies(dfaIn.price_per_dfa) * Number(dfaIn.original_amount)
                    : undefined,
            price_per_dfa: dfaIn.price_per_dfa ? getPennies(dfaIn.price_per_dfa) : undefined,
            redeem_price_per_dfa: dfaIn.redeem_price_per_dfa
                ? getPennies(dfaIn.redeem_price_per_dfa)
                : undefined,
            issue_end_date: dfaIn.issue_end_date
                ? (safeToUtc(dfaIn.issue_end_date, true) as number)
                : undefined,
            redeem_date: dfaIn.redeem_date
                ? (safeToUtc(dfaIn.redeem_date, true) as number)
                : undefined,
            type_of_law: dfaIn.type_of_law,

            document: getDocumentUid(dfaIn.document) as string,
            document_signature: getDocumentUid(dfaIn.document_signature) as string,
            upload_date: currentUnix(),
            handle_date: currentUnix(),
            operator_id: dfaIn.emitter_id,
            ois_info: dfaIn.ois_info || 'op-101',
            reject_reason: '',
            attachments: getDocumentUid(dfaIn.attachments) as string,
            admission_application: getDocumentUid(dfaIn.admission_application) as string,
            admission_application_signature: getDocumentUid(
                dfaIn.admission_application_signature,
            ) as string,

            issue_available_investors_type: (dfaIn.issue_available_investors_type ===
            IssueAvailableInvestorsType.qualified_and_unqualified
                ? dfaIn.issue_available_investors_type_limits
                : dfaIn.issue_available_investors_type) as IssueAvailableInvestorsType,
            issue_conditions: dfaIn.issue_conditions,

            is_coupon_payment: dfaIn.is_coupon_payment,
            coupon_payment_method_type: dfaIn.coupon_payment_method_type,
            coupon_payment_amount: dfaIn.coupon_payment_amount
                ? getPennies(dfaIn.coupon_payment_amount)
                : undefined,
            coupon_payment_amount_description: dfaIn.coupon_payment_amount_description,
            coupon_payment_collect_type: dfaIn.coupon_payment_collect_type,
            is_coupon_paid_before_holiday_or_weekend:
                dfaIn.is_coupon_paid_before_holiday_or_weekend,
            coupon_payment_calendar: dfaIn.coupon_payment_calendar,

            // нет пока в свагере, но должно быть позже https://masterchain.atlassian.net/browse/DFA-7543
            coupon_settlement_type: dfaIn.coupon_settlement_type ?? 'account',
            issue_fee_amount: dfaIn.issue_fee_amount,
        },

        investment_spread: getBasePercent(
            stringToNumber(dfaIn.investment_spread as unknown as string),
        ),
        redemption_spread: getBasePercent(
            stringToNumber(dfaIn.redemption_spread as unknown as string),
        ),
        supply_invested_type: dfaIn.supply_invested_type,
        min_supply_invested:
            dfaIn.supply_invested_type === SupplyInvestedType.diapason
                ? Number(dfaIn.min_supply_invested)
                : Number(dfaIn.max_supply_invested),
        max_supply_invested: Number(dfaIn.max_supply_invested),

        is_early_repayment: dfaIn.is_early_repayment,
        early_repayment_type:
            dfaIn.early_repayment_type !== undefined && dfaIn.early_repayment_type
                ? 'period'
                : 'last_day',
        period_for_early_repayment: Number(dfaIn.period_for_early_repayment),
        early_redemption_spread: dfaIn.early_redemption_spread
            ? Number(dfaIn.early_redemption_spread)
            : undefined,
    }
}

export const dfaBackToFront = (
    dfaIn: IDfaBack,
    emitterInfo?: IDfaEmitterInfo,
    availableData?: IDfaAvailable,
    rate?: number,
): IDfaFront => {
    let decision = {} as IDfaDecision

    if (dfaIn?.decision) {
        decision = { ...dfaIn.decision } as IDfaDecision

        let redeem_price_per_dfa = 0
        let price_per_dfa = 0
        if (dfaIn?.price_source_type === PriceSource.dynamic) {
            if (dfaIn.price_external) {
                redeem_price_per_dfa = Number(
                    (dfaIn.price_external * (1 + (dfaIn.redemption_spread ?? 0) / 100000)).toFixed(
                        2,
                    ),
                )
            } else if (rate) {
                redeem_price_per_dfa = Number(
                    ((rate + (rate / 100) * ((dfaIn.redemption_spread ?? 0) / 1000)) / 100).toFixed(
                        2,
                    ),
                )
            }

            if (dfaIn.price_external) {
                price_per_dfa = Number(
                    (dfaIn.price_external * (1 + (dfaIn.investment_spread ?? 0) / 100000)).toFixed(
                        2,
                    ),
                )
            } else if (rate) {
                price_per_dfa = Number(
                    ((rate + (rate / 100) * ((dfaIn.investment_spread ?? 0) / 1000)) / 100).toFixed(
                        2,
                    ),
                )
            }
        } else {
            redeem_price_per_dfa = Number(decision.redeem_price_per_dfa ?? 0) / 100
            price_per_dfa = Number(decision.price_per_dfa ?? 0) / 100
        }

        decision.decision_id = decision.id
        decision.issue_date = decision.issue_date && decision.issue_date * 1000
        decision.issue_end_date = decision.issue_end_date && decision.issue_end_date * 1000
        decision.issue_start_date = decision.issue_start_date && decision.issue_start_date * 1000
        decision.redeem_date = decision.redeem_date && decision.redeem_date * 1000
        decision.handle_date = decision.handle_date && decision.handle_date * 1000
        decision.upload_date = decision.upload_date && decision.upload_date * 1000
        decision.document = getInitialFile(decision.document as string) as UploadFile[]
        decision.document_signature = getInitialFile(
            decision.document_signature as string,
        ) as UploadFile[]
        decision.attachments = getInitialFile(decision.attachments as string) as UploadFile[]
        decision.admission_application = getInitialFile(
            decision.admission_application as string,
        ) as UploadFile[]
        decision.admission_application_signature = getInitialFile(
            decision.admission_application_signature as string,
        ) as UploadFile[]
        decision.redeem_price_per_dfa = redeem_price_per_dfa
        decision.current_redeem_price_per_dfa = decision.current_redeem_price_per_dfa
            ? Number(decision.current_redeem_price_per_dfa) / 100
            : decision.current_redeem_price_per_dfa
        decision.price_per_dfa = price_per_dfa
        decision.original_price = decision.original_price
            ? Number(decision.original_price) / 100
            : decision.original_price

        const investors_type = decision.issue_available_investors_type
        decision.issue_available_investors_type = [
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit,
            IssueAvailableInvestorsType.qualified_and_unqualified_without_limit,
        ].includes(decision.issue_available_investors_type)
            ? IssueAvailableInvestorsType.qualified_and_unqualified
            : IssueAvailableInvestorsType.qualified

        decision.issue_available_investors_type_limits =
            decision.issue_available_investors_type ===
            IssueAvailableInvestorsType.qualified_and_unqualified
                ? investors_type
                : null

        decision.issue_conditions =
            dfaIn.issue_type === IssueTypeDfa.auto &&
            decision.issue_end_date &&
            !decision.issue_conditions
                ? `Заключение договоров о приобретении ЦФА в отношении не менее чем ${
                      decision.original_amount
                  } ЦФА в срок не позднее 
                ${dayjs.unix(decision.issue_end_date).format('DD.MM.YYYY')}`
                : decision.issue_conditions

        // нет пока в свагере, но должно быть позже https://masterchain.atlassian.net/browse/DFA-7543
        decision.coupon_settlement_type = decision.coupon_settlement_type ?? 'account'

        decision.coupon_payment_amount = decision?.coupon_payment_amount
            ? Number(decision.coupon_payment_amount ?? 0) / 100
            : undefined
    }

    return {
        ...dfaIn,
        ...decision,
        investors: dfaIn.investors ?? [],
        is_limited_circle_investors: dfaIn.is_limited_circle_investors as boolean,
        available_original_price: dfaIn.available_original_price as number,
        available_rest_price: dfaIn.available_rest_price as number,
        title: dfaIn.title as string,
        ticker_symbol: dfaIn.ticker_symbol as string,
        emitter_full_name: dfaIn.emitter_full_name as string,
        redemption_description: dfaIn.redemption_description as string,
        redemption_type: dfaIn.redemption_type as RedemptionTypeDfa,
        status: dfaIn.status as DfaStatusEnum,
        decision_making_type: dfaIn.decision_making_type as DecisionMakingType,
        issue_settlements_type: dfaIn.issue_settlements_type as SettlementsType,
        update_date: dfaIn?.update_date && dfaIn?.update_date * 1000,
        original_amount_dfa: (dfaIn.available_original_price || 0) / 100,
        rest_amount_dfa: (dfaIn?.available_rest_price || 0) / 100,
        emitter_id: dfaIn.emitter_id as string,
        emitter_info: emitterInfo,
        available: availableData,
        issue_type: dfaIn.issue_type as IssueTypeDfa,
        total_supply_invested: dfaIn.total_supply_invested as number,
        is_force_repayment: dfaIn.is_force_repayment as boolean,
        price_source_type: dfaIn.price_source_type as PriceSource,
        price_source: dfaIn.price_source as ExternalRateIndex,
        investment_spread: dfaIn.investment_spread
            ? dfaIn.investment_spread / 1000
            : dfaIn.investment_spread,
        redemption_spread: dfaIn.redemption_spread
            ? dfaIn.redemption_spread / 1000
            : dfaIn.redemption_spread,
        price_external: dfaIn.price_external || 0,

        is_early_repayment: Boolean(dfaIn.is_early_repayment),
        early_repayment_type: dfaIn.early_repayment_type === 'period' ? true : false,
        period_for_early_repayment: dfaIn.period_for_early_repayment,
        early_redemption_spread: dfaIn.early_redemption_spread,
    }
}
